import { mapHelper } from '@/utils/common.js';

// 消息名称
const messageName = [
  { value: 'S_SERVE_ACTIVITY_APPLY', label: '活动广场-活动申请通知' },
  { value: 'S_SERVE_ACTIVITY_CANCEL_SUCCESS', label: '活动广场-活动取消成功' },
  { value: 'S_IOT_NOTIFY_ALERT', label: '物联设备-预警' },
  { value: 'S_HEALTH_OLDER_MALL_ORDER', label: '老年食堂-订餐通知' },
  { value: 'S_NEIGHBOUR_TALENT_APPLY', label: '社区达人-申请通知' },
  { value: 'S_NEIGHBOUR_TALENT_AUDIT', label: '社区达人-审核结果通知' },
  { value: 'S_NEIGHBOUR_ASSOCIATION_APPLY', label: '社群-申请' },
  { value: 'S_NEIGHBOUR_ASSOCIATION_AUDIT', label: '社群-审核通过' },
  { value: 'S_SERVE_POINT_MALL_GOODS_VERIFICATION', label: '积分商城-商品核销' },
  { value: 'S_SERVE_POINT_MALL_SHOP_APPLY', label: '积分商城-商圈申请' },
  { value: 'S_SERVE_POINT_MALL_SHOP_AUDIT', label: '积分商城-商圈审核' },
  { value: 'S_SERVE_WORK_ORDER_REDEPLOY', label: '工单管理-工单转派' },
  { value: 'S_SERVE_WORK_ORDER_ADD', label: '工单管理-事件上报' },
  { value: 'S_SERVE_WORK_ORDER_COMPLETE', label: '工单管理-处理完成' },
  { value: 'S_SERVE_SPACE_ORDER_APPLY', label: '场地预约-预约申请' },
  { value: 'S_SERVE_SPACE_ORDER_CONFIRM', label: '场地预约-预约确认' },
  { value: 'S_SERVE_SPACE_ORDER_CANCEL', label: '场地预约-预约取消' }
];
const { map: messageNameMap, setOps: setMessageNameOps } = mapHelper.setMap(messageName);

export { messageName, messageNameMap, setMessageNameOps };
