<template>
  <div class="log-list">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isManySelect="false"
      :isIndex="false"
      :isOperateColumn="true"
      rowKey="logId"
      :operateColumnWidth="150"
    >
      <template #searchSlot>
        <v-select
          label="消息名称"
          v-model="searchParam.logIds"
          clearable
          filterable
          :options="messageNameOptions"
          placeholder="请选择消息名称"
          :width="300"
          @change="$refs.list.search()"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="详情" type="text" @click="detailOnClick(scope.row, scope.$index)" />
      </template>
    </v-list>

    <v-dialog title="详情" v-model="detailDiaShow" width="70%" customClass="msg-detail-dialog" top="100px" :isShowcancelBtn="false">
      <v-list
        ref="xqlist"
        :key="this.detailForm.logIds + detailDiaShow"
        :isAdd="true"
        :headers="detailHeaders"
        :tableUrl="detailTableUrl"
        :searchParam.sync="detailForm"
        :isManySelect="false"
        :isIndex="false"
        :isOperateColumn="false"
        :isHead="false"
      >
        <template #searchSlot>
          <v-input label="用户名称" v-model="detailForm.optUser" placeholder="请输入用户名称" />
          <v-date-picker
            label="发送时间"
            v-model="sendTime"
            type="daterange"
            formatValue="yyyy-MM-dd"
            format="yyyy-MM-dd"
            startPlaceholder="开始时间"
            endPlaceholder="结束时间"
          />
        </template>
      </v-list>
    </v-dialog>
  </div>
</template>

<script>
import { getMessageListUrl, getMessageDetailListUrl } from './api.js';
import { messageNameMap, setMessageNameOps } from './map.js';

export default {
  name: 'pubCapMsgCentMessageList',
  data() {
    return {
      messageNameOptions: setMessageNameOps(),
      searchParam: {
        // tenantId: '',
        logIds: ''
      },
      tableUrl: getMessageListUrl,
      headers: [
        {
          prop: 'logId',
          label: '消息名称',
          align: 'center',
          formatter: (row, prop) => {
            return messageNameMap[row[prop]] || '';
          }
        },
        {
          prop: 'msgCnt',
          label: '发送次数',
          width: 150,
          align: 'center'
        }
      ],
      detailDiaShow: false,
      detailForm: {
        // tenantId: '',
        logIds: '',
        optUser: '',
        startDate: '',
        endDate: ''
      },
      detailTableUrl: getMessageDetailListUrl,
      detailHeaders: [
        {
          prop: 'optUser',
          label: '用户名称',
          width: 150,
          align: 'center'
        },
        {
          prop: 'logData',
          label: '消息内容',
          align: 'center'
        },
        {
          prop: 'createTime',
          label: '发送时间',
          width: 200,
          align: 'center'
        },
        {
          prop: 'logLevel',
          label: '推送是否成功',
          width: 150,
          align: 'center',
          formatter: (row, prop) => {
            if (row[prop] === 'error') {
              return '失败';
            } else {
              return '成功';
            }
          }
        }
      ]
    };
  },
  computed: {
    sendTime: {
      get() {
        return !this.detailForm.startDate && !this.detailForm.endDate ? [] : [this.detailForm.startDate, this.detailForm.endDate];
      },
      set(val) {
        [this.detailForm.startDate, this.detailForm.endDate] = val || ['', ''];
      }
    }
  },
  created() {
    // this.searchParam.tenantId = this.$store.state.app.userInfo.tenantId;
    // this.detailForm.tenantId = this.$store.state.app.userInfo.tenantId;
  },
  mounted() {},
  methods: {
    detailOnClick(row, index) {
      this.detailForm.logIds = row.logId;
      this.detailDiaShow = true;
    }
  }
};
</script>

<style lang="less" scoped>
.log-list {
  box-sizing: border-box;
  height: 100%;
}

::v-deep .msg-detail-dialog {
  .el-dialog__footer {
    display: none;
  }
  .el-dialog__body {
    height: calc(100vh - 300px);
  }
}
</style>
